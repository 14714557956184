/**
 * site
 **/

// Initilize debugging with 'localStorage.debug = true' in your console
var log = bows('site');

// log('some var we need to check');

const siteInit = () => {
  // MobileMenuDefault();
  // SearchSiteDefault();
  fitvids();
};

var debounceActions = function() {
  // add JS function calls here for resize actions
};
var debounceCallable = debounce(debounceActions, 250);

jQuery(document).ready(function() {
  siteInit();
});

jQuery(window).resize(function() {
  debounceCallable();
});
